<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <a
          href="https://materialdesignicons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >Icons</a>
      </v-card-title>
      <v-card-text>
        <icon-list />
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import IconList from './IconList.vue'
export default {
  components:{
    IconList
  },

}
</script>
<style lang="">

</style>
